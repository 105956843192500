<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    productId: {
      type: String
    }
  })
  
  const data = reactive({
    dataList: []
  })

  const getProductExpand = () => {
    const parms = { productId: props.productId }
    // console.log(parms, 'parms')
    api.product
      .getProductExpand(parms)
      .then((result) => {
        data.dataList = result.data
        console.log(result, 'getProductExpand')
      })
      .catch((err) => {})
  }

  defineExpose({
    getProductExpand
  })
</script>

<template>
  <div class="list-box">
    <template v-if="data.dataList.length > 0">
      <div v-for="(item, index) in data.dataList" :key="index" class="item-box">
        <div class="title user-select-text">{{ item.title }}</div>
        <div class="pro-box">
          <div v-for="(proItem, proIndex) in item.list" :key="proIndex" class="rowSS pro-item">
            <div class="lable user-select-text" v-html="proItem.expandKey"></div>
            <div class="text user-select-text" v-html="proItem.expandValue"></div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="rowCC w100">
      <el-empty image="https://cdn.qingmeidata.com/d7b07b30.png" description="No Data" :image-size="400" />
    </div>
  </div>
</template>

<style lang="less" scoped>
  .list-box {
    font-size: 14px;
    color: #909399;
  }
  .item-box {
    margin: 0 16px 0 40px;
    padding: 16px 0;
    border-top: 1px solid #DCDFE6;
    .title {
      font-size: 16px;
      font-weight: 500;
      padding: 12px 24px;
      white-space: normal; /* 允许换行 */
      word-wrap: break-word; /* 单词换行 */
      overflow-wrap: break-word; /* 单词换行（兼容性考虑） */
    }
    .pro-box {
      flex: 0 0 80%;
      .pro-item {
        width: 100%;
        .lable {
          flex: 0 0 360px;
          padding: 8px 16px;
          text-align: right;
          align-self: stretch;
          text-align: right;
          /* flex-direction: row-reverse; */
        }
        .text {
          flex: 1;
          padding: 8px 24px;
        }
      }
    }
    &:first-child {
      border-top: none;
    }
  }
</style>
