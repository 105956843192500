<script setup>
  import Rating from '@/views/order/compontent/rating'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const props = defineProps({
    reviewItem: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isLastItem: {
      type: Boolean,
      default: false
    }
  })

  const data = reactive({
    images: []
  })

  const handlePreviewlist = (list) => {
    if (Array.isArray(list)) {
      data.images = list.map((item) => JLTools.formatUrl(item.imgUrl))
    }
  }

  onMounted(() => {
    handlePreviewlist(props.reviewItem.images)
  })
</script>

<template>
  <div class="review-item" :style="{ borderBottom: props.isLastItem ? 'none' : '1px solid #DCDFE6' }">
    <div class="flex_center">
      <div class="mr15">
        <img v-if="props.reviewItem.headImage" style="border-radius: 50%;" height="48" width="48" :src="JLTools.formatUrl(props.reviewItem.headImage)" />
        <img v-else height="48" width="48" style="border-radius: 50%;" src="https://cdn.qingmeidata.com/d445f253.png" />
      </div>
      <div>
        <div class="user-name">{{ props.reviewItem.anonymousUser ? `${props.reviewItem.nickName.substr(-20, 3)}**` : props.reviewItem.nickName }}</div>
        <Rating :curRating="props.reviewItem.avgValue ? props.reviewItem.avgValue : props.reviewItem.level" />
      </div>
      <!-- <div>{{ props.reviewItem.createdCommentRecordTime }}</div> -->
    </div>
    <div class="content">{{ props.reviewItem?.content }}</div>
    <div class="rowSC" v-if="data.images && data.images.length > 0" style="flex-wrap: wrap;">
      <div v-for="(image, index) in data.images" :key="index">
        <el-image class="image mr10 mb15" :src="image" :preview-src-list="data.images" :hide-on-click-modal="true" :z-index="999" />
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .review-item {
    color: #222;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    .user-name {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: var(--mainBgColor);
    }
    .content {
      margin: 10px 0 16px 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #606266;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
    .image {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */
    }
  }
</style>
