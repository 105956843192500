<script setup>
  import { getCurrentInstance, onMounted, reactive } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()
  const props = defineProps(['productData', 'productId'])

  const templatePolicyCollect = [
    // Delivery
    { name: 'Original Product Warranty', content: '', iconUrl: 'https://cdn.qingmeidata.com/da69dffc.png' },
    { name: 'Home Delivery', content: 'Fast Express Delivery', iconUrl: 'https://cdn.qingmeidata.com/196c9013.png' }
  ]

  const productPolicyCollect = [
    // Return & Warranty
    { name: 'Easy Return & Refund Policy(7 to 14 days) in case of damage unit', content: '', iconUrl: 'https://cdn.qingmeidata.com/c60f62a5.png' },
    { name: '100% Original Product', content: '', iconUrl: 'https://cdn.qingmeidata.com/dac37562.png' }
  ]

  const data = reactive({
    loading: false,
    address: {
      areaNo: ''
    },
    productId: null,
    freight: null, // 商品预估运费
    templatePolicyCollect: [], // Delivery
    productPolicyCollect: [] // Return & Warranty
  })

  // 处理默认地址
  const handleAddress = () => {
    let addressList = userStore.addressList
    if (!JLTools.isNull(addressList) && addressList.length > 0) {
      addressList.forEach((res, i) => {
        if (res.defAddress) {
          data.address = res
        }
        if (i === addressList.length - 1 && JLTools.isNull(data.address)) {
          data.address = res
        }
      })
    }
    getProductFreightMin()
  }

  // 获取商品政策信息
  const getProductPolicy = () => {
    // console.log('获取商品政策信息')
    data.loading = true
    api.product
      .getProductPolicy(data.productId)
      .then((res) => {
        // console.log(res, 'RES')
        data.templatePolicyCollect = res.templatePolicyCollect.length ? res.templatePolicyCollect : []
        data.productPolicyCollect = res.productPolicyCollect.length ? res.productPolicyCollect : []
      })
      .finally(() => {
        data.loading = false
      })
  }

  // 查看商品最低运费
  const getProductFreightMin = () => {
    // console.log(data.address.areaNo, 'data.address.areaNo')
    api.product
      .getProductFreightMin([
        {
          productId: data.productId,
          areaNo: data.address.areaNo
        }
      ])
      .then((res) => {
        // console.log(res, 'getProductFreightMin')
        data.freight = res[0].freight
      })
  }

  onMounted(() => {
    data.productId = proxy.$route.params && proxy.$route.params.id ? proxy.$route.params.id : null
    handleAddress()
    getProductPolicy()
  })
</script>

<template>
  <div class="pro-right-box">
    <div class="card pb10" v-loading="data.loading">
      <span class="title">{{ $t('Delivery') }}</span>
      <!-- <div class="item" v-if="data.address">
        <img class="img" src="https://cdn.qingmeidata.com/7d89a282.png" />
        <div class="txt">
          <p class="text_hide3">{{ data.address.address }}{{ ',' }}{{ data.address.country }}{{ ',' }}{{ data.address.city }}{{ ',' }}{{ data.address.province }}{{ '.' }}</p>
        </div>
      </div> -->

      <template v-for="(item, index) in data.templatePolicyCollect" :key="index">
        <div class="item">
          <img class="img" :src="JLTools.formatUrl(item.iconUrl)" />
          <div class="txt">
            <p>{{ item.name }}</p>
            <p class="span" v-if="item.content">{{ item.content }}</p>
          </div>
        </div>
      </template>

      <div class="item">
        <img class="img" src="https://cdn.qingmeidata.com/3b3cec42.png" />
        <div class="txt">
          <p>{{ $t('Available Payment Method') }}</p>
          <p class="span mt10 flex_center">
            <img class="img mr10" style="width: 25px; height: 26px;" src="https://cdn.qingmeidata.com/ed708fd9.png" />
            <img class="img mr10" style="width: 22.25px; height: 24px;" src="https://cdn.qingmeidata.com/71e1fce8.png" />
            <img class="img mr10" style="width: 47px; height: 22px;" src="https://cdn.qingmeidata.com/7e9fdbc7.png" />
            <img class="img" style="width: 22px; height: 16px;" src="https://cdn.qingmeidata.com/25cf514b.png" />
          </p>
        </div>
      </div>

      <!-- 预估运费 -->
    </div>
    <div class="card divider">
      <span class="title">{{ $t('Service') }}</span>
      <template v-for="(item, index) in data.productPolicyCollect" :key="index">
        <div class="item">
          <img class="img" :src="JLTools.formatUrl(item.iconUrl)" />
          <div class="txt">
            <p>{{ item.name }}</p>
            <p class="span" v-if="item.content">{{ item.content }}</p>
          </div>
        </div>
      </template>
    </div>
    <!-- v-if="data.freight" -->
    <div class="card divider" v-if="data.freight">
      <span class="title">{{ $t('Shipping Fee') }}</span>
      <div class="item">
        <img class="img" src="https://cdn.qingmeidata.com/9a2134e1.png" />
        <div class="txt">
          <!-- <p>{{ $t('Shipping Fee') }}</p> -->
          <p class="span">Minimum Rs {{ data.freight }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="card mt20">
      <div class="card-sold rowCC">
        <div class="card-sold-item" v-if="0">
          <p>{{ $t('Products Sold') }}</p>
          <p>{{ props.productData.saleCount }}</p>
        </div>
        <div class="card-sold-item">
          <p>{{ $t('Reviews') }}</p>
          <p>{{ props.productData.commentCount }}</p>
        </div>
        <div class="card-sold-item">
          <p>{{ $t('Favorites') }}</p>
          <p>{{ props.productData.collectedCount }}</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<style lang="less" scoped>
  .pro-right-box {
    width: 317px;
    background: #fff;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #fafbfb;
    background-color: #fafbfb;
    .divider {
      border-top: 1px solid #dcdfe6;
      padding-top: 16px;
    }
    .card {
      width: 100%;
      padding: 16px;
      font-size: 14px;
      .title {
        width: 100%;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        padding: 0px 0 5px;
        color: #303133;
      }
      .item {
        width: 100%;
        padding: 8px 0;
        display: flex;
        // align-items: center;
        .img {
          width: 18px;
          height: 18px;
        }
        .img3 {
          margin-top: 3px;
        }
        .txt {
          width: calc(100% - 84px);
          padding: 0 10px;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--mainBgColor);
          word-wrap: break-word;
          .text_hide3 {
            color: #222;
          }
          .span {
            font-weight: 400;
            font-size: 12px;
            color: #303133;
          }
        }
      }
    }
  }

  .card-sold {
    width: 100%;
    display: flex;
    .card-sold-item {
      width: 100%;
      border-left: 1px solid #eee;
      text-align: center;
      font-weight: 500;
      color: #222;
      &:first-child {
        border-left: none;
      }
    }
    b {
      color: #000;
      font-size: 18px;
      margin-top: 10px;
    }
  }
</style>
