<script setup>
  import Rating from '@/views/order/compontent/rating'
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits, defineExpose, nextTick, inject, watch, computed } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore, HsystemStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const collectionProduct = inject('collectionProduct')

  const props = defineProps({
    productData: {
      type: Object,
      default: () => {}
    },
    recordsProductInfo: {
      type: Object,
      default: () => {}
    }
  })

  const data = reactive({
    loading: false,
    specs: [], // 规格
    specsItem: [], // 规格列表
    curSpecs: [], // 当前规格
    curSpecsData: {}, // 当前规格属性
    systemDate: null,
    timer: null,
    quantity: 1
  })

  // 处理商品信息
  const handleProductData = () => {
    data.loading = true
    // 提取商品规格列表信息
    data.specs = props.productData.specs
    // 默认选择第一个规格
    data.curSpecs = data.specs[0]
    data.curSpecsData = {
      firstAttrValue: data.specs[0].firstAttrValue,
      secondAttrValue: data.specs[0].secondAttrValue,
      thirdAttrValue: data.specs[0].thirdAttrValue
    }
    // 初始化选择规格
    initSelected()
    // 业内导航
    setBreadcrumbList()

    if (props.productData.discount) {
      data.systemDate = new Date().getTime(props.productData.discount.systemDate)
      data.timer = setInterval(updateTime, 1000)
    }

    nextTick(() => {
      data.loading = false
    })
  }

  const updateTime = () => {
    data.systemDate = data.systemDate + 1000
    console.log(data.systemDate, 'updateTime')
  }

  // 计算倒计时时间
  const displayValue = computed(() => {
    if (!props.productData.discount) return
    console.log(data.systemDate, 'data.systemDate')
    const systemDate = data.systemDate
    let endTime = null
    let startTime = null
    let status = null

    if (new Date(props.productData.discount.beginAt).getTime() > systemDate) {
      status = 'Starting In'
      endTime = systemDate
      startTime = new Date(props.productData.discount.beginAt).getTime()
    } else if (new Date(props.productData.discount.endAt).getTime() > systemDate) {
      status = 'Ends in'
      endTime = new Date(props.productData.discount.endAt).getTime()
      startTime = systemDate
    } else {
      status = 'Ended'
      endTime = systemDate
      startTime = new Date(props.productData.discount.endAt).getTime()
    }

    const diff = endTime - startTime

    if (diff <= 0) {
      clearInterval(data.timer)
      return {
        status: status,
        hours: '00',
        minutes: '00',
        seconds: '00'
      }
    }

    const hours = String(Math.floor((diff / (1000 * 60 * 60)) % 24)).padStart(2, '0')
    const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(2, '0')
    const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, '0')
    console.log(status, hours, minutes, seconds, 'status, hours, minutes, seconds')
    return { status, hours, minutes, seconds }
  })

  // 类别处理
  const initSelected = () => {
    if (!JLTools.isNull(data.curSpecsData.thirdAttrValue)) {
      setSelectSpecParams(3)
    } else if (!JLTools.isNull(data.curSpecsData.secondAttrValue)) {
      setSelectSpecParams(2)
    } else if (!JLTools.isNull(data.curSpecsData.firstAttrValue)) {
      setSelectSpecParams(1)
    }
  }

  // 重新查询规格列表和查询当前选中规格
  const setSelectSpecParams = (type) => {
    const sortByAttr = (list, attr) => list.sort((a, b) => a[attr] - b[attr])
    if (type >= 1) {
      data.specsItem.firstAttrList = sortByAttr(
        data.specs.filter((item) => data.curSpecsData.secondAttrValue === item.secondAttrValue && data.curSpecsData.thirdAttrValue === item.thirdAttrValue),
        'firstAttrValue'
      )
    }
    if (type >= 2) {
      data.specsItem.secondAttrList = sortByAttr(
        data.specs.filter((item) => data.curSpecsData.firstAttrValue === item.firstAttrValue && data.curSpecsData.thirdAttrValue === item.thirdAttrValue),
        'secondAttrValue'
      )
    }
    if (type >= 3) {
      data.specsItem.thirdAttrList = sortByAttr(
        data.specs.filter((item) => data.curSpecsData.firstAttrValue === item.firstAttrValue && data.curSpecsData.secondAttrValue === item.secondAttrValue),
        'thirdAttrValue'
      )
    }
    // console.log(data.curSpecsData, 'data.curSpecsData')
    const list = data.specs.filter(
      (item) =>
        data.curSpecsData.thirdAttrValue === item.thirdAttrValue &&
        data.curSpecsData.secondAttrValue === item.secondAttrValue &&
        data.curSpecsData.firstAttrValue === item.firstAttrValue
    )
    data.curSpecs = list[0]
    data.quantity = 1
    // console.log(data.curSpecs.name, data.curSpecs.mrp, data.curSpecs, 'data.curSpecs')
    // console.log(data.specsItem.firstAttrList, 'data.curSpecs.firstAttrList')
  }

  // 选择规格属性
  const selSpecs = (type, item) => {
    switch (type) {
      case 3:
        data.curSpecsData.thirdAttrValue = item.thirdAttrValue
        break
      case 2:
        data.curSpecsData.secondAttrValue = item.secondAttrValue
        break
      default:
        data.curSpecsData.firstAttrValue = item.firstAttrValue
        break
    }
    // console.log(data.curSpecsData, 'data.curSpecsData')
    setSelectSpecParams(type)
  }

  // 增加减少购买商品数量
  const editQuantity = (num) => {
    data.quantity += num
    if (data.quantity > data.curSpecs.stockCount) {
      data.quantity = data.curSpecs.stockCount
    } else if (data.quantity < 1) {
      data.quantity = 1
    }
  }

  // 修改购买商品数量
  const editInputQuantity = () => {
    let reg = /[^\d]/g
    data.quantity = data.quantity.replace(reg, '')
    data.quantity = parseInt(data.quantity, 10) || 1 // 确保为整数，默认为 1
    if (data.quantity > data.curSpecs.stockCount) {
      data.quantity = data.curSpecs.stockCount
    } else if (data.quantity < 1) {
      data.quantity = 1
    }
  }

  // 收藏取消收藏
  const handleCollection = () => {
    collectionProduct()
  }

  // 页内导航
  const setBreadcrumbList = () => {
    HsystemStore.setBreadcrumbList([
      {
        name: props.productData.firstCategory.name,
        data: {
          name: 'blankPage',
          params: {
            pageName: 'productList',
            firstCategoryId: props.productData.firstCategory.id
          }
        }
      },
      {
        name: props.productData.secondCategory.name,
        data: {
          name: 'blankPage',
          params: {
            pageName: 'productList',
            secondCategoryId: props.productData.secondCategory.id
          }
        }
      },
      {
        name: props.productData.thirdCategory.name,
        data: {
          name: 'blankPage',
          params: {
            pageName: 'productList',
            thirdCategoryId: props.productData.thirdCategory.id
          }
        }
      }
      // { name: props.productData.name }
    ])
  }

  const validateAddToCart = () => {
    // 用户未登录时跳转到登录页面
    if (proxy.JLTools.isNull(userStore.userInfo.userId)) {
      router.push({ path: '/login' })
      return false
    }

    // 数据正在加载时，直接返回
    if (data.loading) return false

    // 当前规格不存在或库存为零时，显示售罄提示并返回
    if (!data.curSpecs || data.curSpecs.stockCount === 0) {
      data.loading = false
      proxy.$toast(proxy.$t('common.productStatus.soldOut'))
      return false
    }

    // if (specsItem)

    return true
  }

  // 购买
  const buy = () => {
    if (!validateAddToCart()) return
    const queryList = []
    console.log(data.curSpecs, 'data.curSpecs')
    queryList.push({ specId: data.curSpecs.id, count: data.quantity })
    router.push({
      name: 'SETTLEMENT',
      query: {
        List: JSON.stringify(queryList)
      }
    })
  }

  // 加入购物车
  const addCart = () => {
    if (!validateAddToCart()) return
    data.loading = true
    let params = {
      purchaseNum: data.quantity,
      userId: userStore.userInfo.userId,
      spec: data.curSpecs
    }
    console.log(params, 'addCart')
    api.product
      .addCart(params)
      .then((res) => {
        proxy.$toast(proxy.$t('Added successfully!'), { type: 'succeed' })
        userStore.getCartList()
      })
      .catch((err) => {
        proxy.$toast(proxy.$t(`${err.detail}`), { type: 'error' })
      })
      .finally(() => {
        data.loading = false
      })
  }

  watch(
    () => props.productData,
    (newValue, oldValue) => {
      if (JLTools.isNull(newValue) || JLTools.isNull(newValue.id)) return
      handleProductData()
    }
  )

  defineExpose({
    handleProductData
  })
</script>

<template>
  <div class="pro-main-center" v-loading="data.loading">
    <div class="pro-name-box">
      <div class="pro-name text_hide2">
        {{ productData.name }}
      </div>

      <div class="pro_maidian" v-if="props.recordsProductInfo?.commentRecordCount > 0">
        <Rating :curRating="props.recordsProductInfo.commentCountAvg" />
        <div class="score-num">{{ props.recordsProductInfo.commentCountAvg }} {{ $t('Star Rating') }}</div>
        <div class="num">({{ props.recordsProductInfo.commentRecordCount }} {{ $t('User feedback') }})</div>
      </div>
      <div class="availability">
        <span class="availability-title">{{ $t('Availability') }}:&nbsp;</span>
        <span v-if="data.curSpecs.stockCount > 10" style="color: var(--successTextColor)">In Stock</span>
        <span v-else-if="data.curSpecs.stockCount > 0" style="color: var(--warningTextColor)">Limited Stock</span>
        <span v-else style="color: var(--errorTextColor)">Out of Stock</span>
      </div>
    </div>

    <div class="pro_shux" v-if="!JLTools.isNull(data.specsItem.firstAttrList)">
      <div class="key">
        {{ data.specsItem.firstAttrList[0].firstAttrName }}
      </div>
      <div class="value">
        <div
          class="type_main_img"
          :title="item.name"
          v-for="(item, index) of data.specsItem.firstAttrList"
          :key="index"
          @click="selSpecs(1, item)"
          :class="{ disable: item.disable || data.curSpecs.stockCount == 0, xuan: data.curSpecsData.firstAttrValue == item.firstAttrValue }"
        >
          <div class="img">
            <JLImg v-if="item.coverImg" :src="item.coverImg" />
          </div>
          <span>{{ item.firstAttrValue }}</span>
        </div>
      </div>
    </div>
    <div class="pro_shux" v-if="!JLTools.isNull(data.specsItem.secondAttrList)">
      <div class="key">
        {{ data.specsItem.secondAttrList[0].secondAttrName }}
      </div>
      <div class="value">
        <button
          class="sx type_main_img"
          v-for="(item, index) of data.specsItem.secondAttrList"
          :key="index"
          :class="{ disable: item.disable || data.curSpecs.stockCount == 0, xuan: data.curSpecsData.secondAttrValue == item.secondAttrValue }"
          @click="selSpecs(2, item)"
        >
          {{ item.secondAttrValue }}
        </button>
      </div>
    </div>
    <div class="pro_shux" v-if="!JLTools.isNull(data.specsItem.thirdAttrList)">
      <div class="key">
        {{ data.specsItem.thirdAttrList[0].thirdAttrName }}
      </div>
      <div class="value">
        <button
          class="sx type_main_img"
          v-for="(item, index) of data.specsItem.thirdAttrList"
          :key="index"
          :class="{ disable: item.disable && data.curSpecs.stockCount > 0, xuan: data.curSpecsData.thirdAttrValue == item.thirdAttrValue }"
          @click="selSpecs(3, item)"
        >
          {{ item.thirdAttrValue }}
        </button>
      </div>
    </div>
    <div class="pro-amount-box rowSC">
      <div class="amount-num" v-if="data.curSpecs.discountPrice && displayValue && displayValue.status === 'Ends in'">{{ JLTools.forPrice(data.curSpecs.discountPrice) }}</div>
      <div class="amount-num" v-else>{{ JLTools.forPrice(data.curSpecs.amount) }}</div>
      <div class="percentage-num rowCC" v-if="data.curSpecs.percentage">{{ data.curSpecs.percentage }}</div>
    </div>
    <div class="mrp-num" v-if="data.curSpecs.mrp">{{ JLTools.forPrice(data.curSpecs.mrp) }}</div>
    <div class="pro_shux">
      <div class="key">
        {{ $t('Quantity') }}
      </div>
      <div class="quantity-box rowSC">
        <div class="cart-quantity-item rowSC">
          <img src="~img/jianhao.svg" @click="editQuantity(-1)" :style="{ cursor: data.quantity === 1 ? 'not-allowed' : '' }" />
          <input v-model="data.quantity" @input="editInputQuantity" />
          <img src="~img/jiahao.svg" @click="editQuantity(1)" :style="{ cursor: data.quantity >= data.curSpecs.stockCount ? 'not-allowed' : '' }" />
        </div>
      </div>
    </div>

    <div class="pro_shux pro-button-box">
      <div class="value">
        <button class="btn buy-now-button" @click="buy()">{{ $t('Buy Now') }}</button>
        <button class="btn add-to-cart-button" :disabled="data.loading" @click="addCart()">
          <img src="https://cdn.qingmeidata.com/4b16a128.png" />
        </button>
      </div>
    </div>
    <div class="pro_shux add-to-wishlist" @click="handleCollection">
      <img class="img no1" height="14" width="14" v-if="props.productData.collected" src="https://cdn.qingmeidata.com/6c00f976.png" />
      <img class="img no1" height="14" width="14" v-else src="https://cdn.qingmeidata.com/5c832336.png" />
      <span class="span">{{ props.productData.collected ? $t('Added to Wishlist') : $t('Add to Wishlist') }}</span>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .pro-main-center {
    width: 503px;
    padding: 16px 16px 0px;
  }

  .pro-name-box {
    .pro-name {
      width: 100%;
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 16px;
      font-weight: 400;
      user-select: text;
    }
    .pro_maidian {
      width: 100%;
      color: #222;
      margin-bottom: 24px;
      user-select: text;
      display: flex;
      align-items: center;
      :deep(.wujiaoxing) {
        font-size: 14px;
        margin-right: 0px;
      }
      .score-num {
        font-size: 12px;
        font-weight: 400;
        margin-left: 6px;
        margin-right: 6px;
        line-height: 12px;
        color: var(--mainBgColor);
      }
      .num {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #909399;
      }
    }
    .availability {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
      .availability-title {
        color: #909399;
      }
    }
  }

  .pro_shux {
    width: 100%;
    margin-bottom: 24px;
    display: grid;
    .n {
      background: var(--btnBgColor);
      border-color: var(--btnBgColor);
      color: #fff;
    }
    .key {
      line-height: 20px;
      font-size: 14px;
      color: var(--mainBgColor);
      font-weight: 400;
    }
    .value {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .disable {
        color: #757575;
        cursor: not-allowed !important;
        &:hover {
          border: 1px solid var(--btnBgColor) !important;
        }
      }
      .sx {
        background: #fff;
        padding: 10px;
        cursor: pointer;
      }
      .xuan {
        border: 0.5px solid var(--Zcolor);
        color: var(--mainBgColor);
      }
    }
    .type_main_img {
      padding: 2px;
      align-items: center;
      height: 40px;
      border: 0.5px solid #d2d2d3;
      margin-left: 10px;
      border-radius: 4px;
      display: flex;
      margin-top: 8px;
      color: #a8abb2;
      line-height: 20px;
      font-weight: 400;
      font-size: 14px;
      .img {
        width: 32px;
        height: 32px;
      }
      span {
        margin: 0 8px;
      }
      &:first-child {
        margin-left: 0px;
      }
      &:hover {
        cursor: pointer;
        border: 0.5px solid var(--Zcolor);
        color: var(--mainBgColor);
      }
    }
  }

  .pro-amount-box {
    width: 100%;
    padding-top: 16px;
    line-height: 40px;
    border-top: 1px solid #dcdfe6;
    .amount-num {
      font-size: 30px;
      line-height: 38px;
      color: var(--Zcolor);
    }
    .percentage-num {
      min-width: 80px;
      height: 28px;
      padding: 6px 12px;
      margin-left: 8px;
      font-size: 12px;
      line-height: 12px;
      color: #fcf6ec;
      background-color: var(--warningTextColor);
      border-radius: 4px;
    }
  }

  .mrp-num {
    font-size: 14px;
    line-height: 20px;
    color: #909399;
    text-decoration: line-through;
    margin-top: 4px;
    margin-bottom: 24px;
  }

  .quantity-box {
    box-sizing: border-box;
    flex-wrap: wrap;
    .cart-quantity-item {
      background: #fff;
      margin-top: 8px;
      img {
        padding: 10px;
        width: 50px;
        height: 38px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #e4e7e9;
        &:first-child {
          border-right: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        &:last-child {
          border-left: 0px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
        }
        &:hover {
          cursor: pointer;
          background: #eee;
        }
      }
      input {
        text-align: center;
        width: 40px;
        height: 38px;
        border: 1px solid #e4e7e9;
        border-left: 0px;
        border-right: 0px;
        color: #303133;
      }
    }
  }

  .pro-button-box {
    margin: 0px !important;
    .add-to-cart-button {
      width: 61px !important;
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn {
      width: 390px;
      height: 48px;
      font-size: 16px;
      padding: 8px 16px;
      color: var(--btnBgColor);
      background: #fff;
      border-radius: 8px;
      border: 1px solid var(--btnBgColor);
      cursor: pointer;
      box-sizing: border-box;
      &:first-child {
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        border: none;
        background-color: var(--btnBgColor);
        color: var(--mainTextColor);
      }
    }
  }

  .add-to-wishlist {
    display: flex !important;
    align-items: center;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 24px;
    .span {
      margin-left: 6px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #606266;
    }
  }
</style>
